import React, { useContext, useState } from "react";
import SelectDetails from "./SelectDetails";
import PackageDetails from "./PackageDetails";
import { ArrowBack, DateCalender } from "../../../assets/svg/AllSvg";
import ReactDatePicker from "react-datepicker";
import layoutContext from "../../../layouts/layoutContext";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InputError, InputWithLabel } from "../../../components/micro";
import moment from "moment";

const SelectDateTime = ({
  formvalue,
  setFormValue,
  selectedPackages,
  handleRemovePackage,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [isnext, setIsNext] = useState(true);
  const [counting, SetCounting] = useState([]);
  const { prev, next } = useContext(layoutContext);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          performance_date_time: "",
          performance_address: "",
          performance_postal_code: "",
        }}
        validationSchema={Yup.object().shape({
          performance_date_time: Yup.string()
            .nullable()
            .required("Please Select Performance Date Time"),
          performance_address: Yup.string()
            .min(3)
            .required("Please Enter Address"),
          performance_postal_code: Yup.string()
            .required("Please Enter Postal Code")
            .min(5, "Postal Code must be at least 5 characters")
            .matches(/^\d+$/, "Postal Code must be numeric"),
        })}
        onSubmit={(values) => {
          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="grid sm:grid-cols-3 grid-rows items-start gap-2 my-3">
              <div className="bg-white rounded-2xl text-black py-5 px-6 sm:col-span-2">
                <div>
                  <div className="cursor-pointer ">
                    <button
                      onClick={() => prev()}
                      className="flex gap-2 items-center"
                    >
                      <ArrowBack />
                      <p>Back</p>
                    </button>
                  </div>
                  <div className="flex items-center gap-3">
                    <DateCalender />
                    <p className="text-brown md:text-2xl text-base font-normal">
                      Choose Your Preferred Date, Time and Location
                    </p>
                  </div>
                  <div>
                    <div className="bg-lightPrimaryGrey border border-secondaryLightGrey rounded-xl p-4 my-4">
                      <div>
                        {/* Date */}
                        <div>
                          <p className=" text-sm text-primaryblack">
                            Please Select Your Preferred Date & Time
                          </p>
                          <div
                            className={`${
                              errors.performance_date_time &&
                              touched.performance_date_time
                                ? ` input-error `
                                : ` `
                            } max-w-3xl py-2 focus-within:border-themeBtn hover:border-mainthemeBlack `}
                          >
                            <ReactDatePicker
                              selected={startDate}
                              onChange={(date) => {
                                setStartDate(date);
                                setFieldValue(
                                  "performance_date_time",
                                  moment(date).format("YYYY-MM-DD HH:mm:ss")
                                );
                                setFormValue({
                                  performance_date_time: moment(date).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  ),
                                  performance_address:
                                    formvalue?.performance_address,
                                  performance_postal_code:
                                    formvalue?.performance_postal_code,
                                });
                              }}
                              timeCaption="Select a Time"
                              id={"date"}
                              dateFormat="dd/MM/yyyy | hh:mm aa"
                              showTimeSelect
                              placeholderText="Select Date & Time"
                              className="w-full border border-gray-300 rounded-md px-3 py-3 focus:outline-none"
                            />
                          </div>
                          {errors.performance_date_time &&
                          touched.performance_date_time ? (
                            <InputError
                              errorTitle={errors.performance_date_time}
                            />
                          ) : null}
                        </div>
                        <div>
                          <p className=" text-sm text-primaryblack mb-2">
                            Please Enter the Location For The Performance
                          </p>
                          <div className="sm:grid sm:grid-cols-2 gap-3 ">
                            {/* Address */}
                            <InputWithLabel
                              className={` ${
                                errors.performance_address &&
                                touched.performance_address
                                  ? `border border-red`
                                  : `border border-borderTheme/80 focus-within:border-themeBtn `
                              } transition duration-300 ease-in-out sm:my-0 my-3`}
                              idFromName="address"
                              label="Address*"
                              type="text"
                              onChange={(e) => {
                                setFieldValue(
                                  "performance_address",
                                  e.target.value
                                );
                                handleChange(e);
                                setFormValue({
                                  performance_date_time:
                                    formvalue?.performance_date_time,
                                  performance_address: e.target.value,
                                  performance_postal_code:
                                    formvalue?.performance_postal_code,
                                });
                                if (e.target.value) {
                                  setIsNext(false);
                                }
                              }}
                              onBlur={handleBlur}
                              value={values.performance_address}
                              errors={
                                errors.performance_address &&
                                touched.performance_address
                              }
                              errorsText={errors.performance_address}
                            />
                            {/* Postal Code */}
                            <InputWithLabel
                              className={` ${
                                errors.performance_postal_code &&
                                touched.performance_postal_code
                                  ? `border border-red`
                                  : `border border-borderTheme/80 focus-within:border-themeBtn `
                              } transition duration-300 ease-in-out`}
                              idFromName="postalcode"
                              label="Postal Code*"
                              type="numeric"
                              onChange={(e) => {
                                setFieldValue(
                                  "performance_postal_code",
                                  e.target.value
                                );
                                setFormValue({
                                  performance_date_time:
                                    formvalue?.performance_date_time,
                                  performance_address:
                                    formvalue?.performance_address,
                                  performance_postal_code: e.target.value,
                                });
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) {
                                  handleChange(e);
                                }
                                handleChange(e);
                                if (e.target.value) {
                                  setIsNext(false);
                                }
                              }}
                              onBlur={handleBlur}
                              value={values.performance_postal_code}
                              errors={
                                errors.performance_postal_code &&
                                touched.performance_postal_code
                              }
                              errorsText={errors.performance_postal_code}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="space-y-3 max-h-[500px] overflow-auto ">
                    {selectedPackages.map((pkg, index) => (
                      <div className="card">
                        <PackageDetails
                          setIsNext={setIsNext}
                          key={index}
                          pkg={pkg}
                          no_of_items={pkg?.no_of_items}
                          id={pkg?.id}
                          isAdded={true}
                          cartInfo={pkg}
                          counting={counting}
                          SetCounting={SetCounting}
                          handleRemovePackage={handleRemovePackage}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <SelectDetails
                next={next}
                isnext={isnext}
                isDate={true}
                selectedPackages={selectedPackages}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SelectDateTime;
