import React from "react";
import { AddPrimaryButton } from "../../../components/micro";
import {
  Calender,
  Close,
  Handshake,
  SelectVector,
} from "../../../assets/svg/AllSvg";

const SelectDetails = ({
  next,
  isnext,
  selectedPackages,
  isStep,
  isContact,
  isDate,
}) => {
  const handleAddToCart = () => {
    if (isStep) {
      if (selectedPackages?.length > 0) {
        next();
      } else {
        console.log("No packages selected");
      }
    } else {
      if (!isContact) {
        next();
      }
    }
  };

  return (
    <>
      <div className="bg-light_grey rounded-2xl text-black py-5 px-6">
        <div>
          <div className="flex items-center gap-2">
            <SelectVector />
            <p className="lg:text-xl  text-sm font-normal text-brown">
              {" "}
              Selected package ({selectedPackages?.length})
            </p>
          </div>
          <div className="p-2 bg-white rounded-lg mt-2 max-h-[500px] overflow-auto">
            <div className=" flex items-center justify-between p-1 bg-lightGrey rounded">
              <p className="lg:text-xl text-base text-mainthemeBlack">
                Total:{" "}
              </p>
              <p className="lg:text-3xl sm:text-xl text-lightBrown">
                $
                {selectedPackages
                  .reduce(
                    (acc, pkg) =>
                      acc + parseFloat(pkg.price || 0) * (pkg.no_of_items || 0),
                    0
                  )
                  .toFixed(2)}
              </p>
            </div>
            <div className="mt-2">
              {selectedPackages?.length > 0 ? (
                selectedPackages?.map((pkg, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center border-b border-b-primaryGrey"
                  >
                    <div className="flex items-center gap-2">
                      <p>{index + 1}.</p>
                      <div>
                        <p className="lg:text-2xl text-base font-normal text-primaryBlack">
                          ${pkg?.price}
                        </p>
                        <p className="lg:text-xl text-sm font-normal text-primaryBlack pb-2">
                          {pkg?.name}
                        </p>
                      </div>
                    </div>
                    <p>x{pkg?.no_of_items}</p>
                  </div>
                ))
              ) : (
                <p className="text-center text-sm text-primaryblack p-6">
                  Start Selecting The Package You Will Like To Engage With Us
                </p>
              )}
            </div>
            <div className="bg-grey p-2 rounded-b-lg flex flex-col gap-2">
              <div className="flex gap-2">
                <Calender />
                <p className="text-xs font-normal text-primaryblack">
                  30 Days Credit Payment
                </p>
              </div>
              <div className="flex gap-1">
                <Handshake />
                <p className="text-xs font-normal text-primaryblack">
                  50% Cancellation Fees
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3">
          {isDate ? (
            <AddPrimaryButton
              className={`${
                !isnext && selectedPackages?.length
                  ? " bg-mainthemeBlack"
                  : "bg-lightprimaryGrey"
              }`}
              value="Next"
              width={"100%"}
              disabled={isnext}
              type={"submit"}
            />
          ) : (
            <AddPrimaryButton
              className={`${
                !isnext && selectedPackages?.length
                  ? " bg-mainthemeBlack"
                  : "bg-lightprimaryGrey"
              }`}
              value="Next"
              width={"100%"}
              disabled={isnext}
              onClick={handleAddToCart}
              type={"submit"}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SelectDetails;
